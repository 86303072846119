//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as am4core from '@amcharts/amcharts4/core';
import * as am4plugins_sunburst from '@amcharts/amcharts4/plugins/sunburst';
import am4themes_dark from '@amcharts/amcharts4/themes/dark';


import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import * as _ from 'underscore';

am4core.useTheme(am4themes_animated);
export default {
  name: 'topics',
  data() {
    return {
      showChart: true,
      showInnerLoading: false,
      showInnerLoadingTree: false,
      showPagination: false,
      Sunburst_chart_data: [],
      isCall: false,
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: "getDarkTheme"
    })
  },
  watch:{
    darkTheme(){
      this.generateSunburstchart()
    }
  },
  methods: {
    ...mapActions({
      requestConversationsTopicParameters: 'requestConversationsTopicParameters',
      generateXMLFile: 'generateXMLFile',
    }),
    generateSunburstchart() {
      const that = this;
      if (this.chart) {
        this.chart.dispose();
      }
      if(this.darkTheme === true){
        am4core.useTheme(am4themes_dark);
      }else{
        am4core.unuseTheme(am4themes_dark);
      }
      // create chart
      const chart = am4core.create(
        that.$refs.sunburstChartNew,
        am4plugins_sunburst.Sunburst,
      );
      chart.padding(0, 0, 0, 0);
      chart.radius = am4core.percent(98);

      chart.data = that.Sunburst_chart_data;
      chart.colors.step = 2;
      chart.fontSize = 11;
      chart.innerRadius = am4core.percent(20);

      // define data fields
      chart.dataFields.value = 'value';
      chart.dataFields.name = 'name';
      chart.dataFields.children = 'children';

      const level0SeriesTemplate = new am4plugins_sunburst.SunburstSeries();
      chart.seriesTemplates.setKey('0', level0SeriesTemplate);

      // this makes labels to be hidden if they don't fit
      level0SeriesTemplate.labels.template.truncate = true;
      level0SeriesTemplate.labels.template.hideOversized = true;
      level0SeriesTemplate.showOnInit = false;
      level0SeriesTemplate.usePercentHack = false;

      level0SeriesTemplate.hiddenState.properties.radius = am4core.percent(0);
      level0SeriesTemplate.radius = am4core.percent(100);
      level0SeriesTemplate.innerRadius = am4core.percent(0);

      const selectedState = level0SeriesTemplate.states.create('selected');
      selectedState.properties.opacity = 0.7;
      level0SeriesTemplate.defaultState.properties.radius = am4core.percent(100);

      let currentlySelected;

      level0SeriesTemplate.slices.template.events.on('over', (event) => {
        if (event.target.dataItem.sunburstDataItem.children) {
          event.target.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        }
      });

      level0SeriesTemplate.slices.template.events.on('hit', (event) => {
        zoomOutButton.show();
        const hitSlice = event.target;

        if (hitSlice.dataItem.sunburstDataItem.children) {
          const series = event.target.dataItem.component;

          if (!series.dummyData) {
            series.tooltip.disabled = true;
            hitSlice.dataItem.label.radius = (hitSlice.radius - hitSlice.pixelInnerRadius) - 7;
            hitSlice.dataItem.label.bent = true;
            hitSlice.dataItem.label.rotation = -180;

            currentlySelected = hitSlice;
            series.dummyData = true;
            series.setState('selected');
            hitSlice.dataItem.sunburstDataItem.series.show();
            series.slices.each((slice) => {
              if (slice != event.target) {
                slice.dataItem.hide();
              }
            });
          } else {
            drillUp(hitSlice);
          }
        }
      });

      level0SeriesTemplate.labels.template.adapter.add('rotation', (rotation, target) => {
        target.maxWidth = target.dataItem.slice.radius - target.dataItem.slice.innerRadius - 10;
        target.maxHeight = Math.abs(target.dataItem.slice.arc * (target.dataItem.slice.innerRadius + target.dataItem.slice.radius) / 2 * am4core.math.RADIANS);
        return rotation;
      });

      const level1SeriesTemplate = level0SeriesTemplate.clone();
      level1SeriesTemplate.hidden = true;
      level1SeriesTemplate.innerRadius = am4core.percent(10);
      chart.seriesTemplates.setKey('1', level1SeriesTemplate);
      level1SeriesTemplate.fillOpacity = 0.75;

      const level2SeriesTemplate = level0SeriesTemplate.clone();
      level2SeriesTemplate.hidden = true;
      level2SeriesTemplate.innerRadius = am4core.percent(20);
      chart.seriesTemplates.setKey('2', level2SeriesTemplate);

      var zoomOutButton = chart.seriesContainer.createChild(am4core.ZoomOutButton);
      zoomOutButton.visible = false;
      zoomOutButton.horizontalCenter = 'middle';
      zoomOutButton.verticalCenter = 'middle';
      zoomOutButton.events.on('hit', () => {
        drillUp(currentlySelected);
      });

      function drillUp(slice) {
        collapse(slice);
        const series = slice.dataItem.component;
        series.tooltip.disabled = false;
        series.dummyData = false;
        series.setState('default');

        series.slices.each((slice) => {
          if (slice != event.target) {
            slice.dataItem.show();
          }
        });

        if (series.parentDataItem.seriesDataItem) {
          currentlySelected = series.parentDataItem.seriesDataItem.slice;
        } else {
          zoomOutButton.hide();
        }
      }

      function collapse(slice) {
        slice.dataItem.label.bent = false;
        slice.dataItem.label.radius = 10;

        if (slice.dataItem.sunburstDataItem.children) {
          slice.dataItem.sunburstDataItem.children.each((child) => {
            child.seriesDataItem.component.setState('hidden');
            collapse(child.seriesDataItem.slice);
          });
        }
      }
      // Enable export
      chart.exporting.menu = new am4core.ExportMenu();
      chart.exporting.adapter.add("formatDataFields", function(data, target) {
        delete data.dataFields.children
      return data;
    });
      chart.exporting.filePrefix = `EVO_Dynamics_report-${that.currentAIAccount.name}-Parameters drilldown-${that.getFilterDate.start}-to-${that.getFilterDate.end}`;
      this.chart = chart
    },
    downloadExcel(reportType) {
      const that = this;
      that.showInnerLoading = true;
      const data = {
        type: reportType,
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.generateXMLFile(data);
      setTimeout(() => {
        that.showInnerLoading = false;
      }, 1000);
    },
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
          from: 'onDateUpdate',
        };
        that.isCall = false;
        that.updateParameterDrilldownData(data);
      }
    },
    updateParameterDrilldownData(data) {
      const that = this;
      if (!that.isCall) {
        that.showChart = true;
        that.showInnerLoading = true;
        that.showPagination = false;
        that.isCall = true;
        if (this.chart) {
          this.chart.dispose();
        }
        that.requestConversationsTopicParameters(data).then((res) => {
          _.map(res.result , (topic) => {
            topic.value = _.reduce(_.compact(_.pluck(topic.children, 'value')), (memo, num) => memo + num, 0)
            console.log(topic.value ,_.pluck(topic.children, 'value'))
          })
          console.log("res ", res.result)
          that.Sunburst_chart_data = res.result;
          that.showInnerLoading = false;
          that.currentPage = 0;
          setTimeout(() => {
            that.generateSunburstchart();
          }, 100);
        })
          .catch((err) => {
            that.showChart = false;
            that.showInnerLoading = false;
          })
          .finally(() => {
            that.isCall = false;
          });
      }
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
        from: 'onAccountUpdate',
      };
      that.isCall = false;
      that.updateParameterDrilldownData(data);
    },
    isValid(value) {
      if ( value === undefined || value === null || (typeof value === 'object' && Object.keys(value).length === 0)|| (typeof value === 'string' && value.trim().length === 0)) {
        return false;
      }
      return true;
    },
    exportSectionToXLS(reportType) {
      const that = this;
      that.showInnerLoading = true;
      const data = {
        type: reportType,
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.generateXMLFile(data);
      setTimeout(() => {
        that.showInnerLoading = false;
      }, 1000);
    }
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
      from: 'mounted',
    };
    that.updateParameterDrilldownData(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
